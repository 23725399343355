<template>
    <nav id="nav" :class="{'relative': relative}">
        <div class="logo-container">
            <img :src="logoPath" @click="goHome" alt="PoolCraft LTD Logo" />
        </div>
        <div v-show="mobileMenu" @click="openMenu" class="mobile-menu-button">
            <font-awesome-layers>
                <font-awesome-icon icon="circle" />
                <font-awesome-icon icon="bars" />
            </font-awesome-layers>
        </div>
        <div class="items" :class="{'close': closeMenu}">
            <NuxtLink to="/">Home</NuxtLink>
            <NuxtLink to="/about">About</NuxtLink>
            <NuxtLink to="/our-services">Our Services</NuxtLink>
            <NuxtLink to="/our-work">Our Work</NuxtLink>
            <NuxtLink to="/gallery">Gallery</NuxtLink>
            <NuxtLink to="/contact">Contact</NuxtLink>
        </div>
        <a class="facebook-link" rel="noopener" href="https://www.facebook.com/PoolCraft-LTD-294443771125910" target="_blank">
            <font-awesome-icon :icon="['fab', 'facebook-square']" />
        </a>
    </nav>
</template>

<script>
export default {
    name: 'navigation',
    data() {
        return {
            relative: true,
            closeMenu: false,
            window: {
                width: 0,
                height: 0
            }
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();

        this.closeMenu = this.mobileMenu;
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    computed: {
        logoPath: function() {
            const image = this.relative ? 'logo_long.png' : 'logo_long_white.png';
            return `https://imagedelivery.net/qnTDfaSAYDET9GI1h1bgDw/pool-craft/${image}/public`
        },
        mobileMenu: function() {
            return this.window.width < 992;
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                // this.relative = this.$route.name != 'index' ? true : false;
                this.closeMenu = true;
            }
        }
    },
    methods: {
        openMenu() {
            this.closeMenu = !this.closeMenu;
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        goHome() {
            this.$router.push('/');
        }
    }
}
</script>